<div class="document">
  <h2 class="document-headline">
    {{ 'DOCUMENT_TYPE.' + documentType | translate }}
  </h2>

  <p class="document-description">
    <mat-icon
      class="dso-icon--small"
      svgIcon="file"
      [attr.aria-label]="
        'REQUEST_DETAILS.DOCUMENT_ICON_ALT_TEXT' | translate
      " />
    {{ name }}
  </p>
</div>
