import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { DocumentDto } from 'src/api/dso-portal/generated/models';

export type DocumentType = DocumentDto['type'];
@Component({
  selector: 'dso-document',
  standalone: true,
  imports: [TranslateModule, MatIconModule],
  templateUrl: './document.component.html',
  styleUrl: './document.component.scss',
})
export class DocumentComponent {
  @Input({ required: true }) documentType!: DocumentType;
  @Input({ required: true }) name!: string;
}
